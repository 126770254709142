@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

/* CSS Document */

* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Poppins', sans-serif !important;
	font-size: 14px;
	background: transparent;
	background: #fbfafa;
}

a {
	outline: none !important;
	text-decoration: none !important;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

p {
	margin: 0;
	padding: 0;
	font-size: 15px;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
	background-color: #f5f5f5;
}

::-webkit-scrollbar {
	width: 7px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background-color: #5d5c5c;
}

:focus {
	outline: none !important;
}

.step_login_sec {
	overflow: hidden;
	position: relative;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	min-height: 100vh;
}

.login_lft {
	/* padding-top: 20vh;
  padding-bottom: 20vh; */
	padding-top: 3%;
	padding-bottom: 3%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	max-width: 23.75rem;
	margin: 0 auto;
	width: 50%;
}

.login_right {
	background: linear-gradient(138.49deg, #b365db 0%, rgba(44, 41, 93, .99) 100%);
	/* background: linear-gradient(138.49deg,#c8e09e 0%,rgb(200, 224, 158,.99)100% ) ; */
	position: relative;
	width: 50%;
}

.login_right::before {
	/* background: #b365db url(../images/Illustration.png) center 100% no-repeat; */
	content: '';
	left: 0;
	height: 100%;
	opacity: 0.8;
	position: absolute;
	top: 0;
	width: 100%;
	background-size: 100%;
}

button {
	padding: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 0;
	border-radius: 0;
	background: 0 0;
	line-height: 1;
	cursor: auto;
	outline: none;
}

.sts-app__heading {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	min-height: 7.75rem;
	margin-bottom: 2.0625rem;
}

.sts-app__logo {
	/* background: url(../images/logo.svg) center center no-repeat; */
	background-size: contain;
	background-position: left center;
	background-repeat: no-repeat;
}

.sts-app__logo {
	position: relative;
	display: block;
	overflow: auto;
	height: 135px;
	width: 217px;
	margin: 0 auto;
}

.sts-app__flow h2 {
	font-size: 25px;
	line-height: 2.0625rem;
	margin-bottom: .375rem;
	font-weight: 400;
	color: #392d7e;
	letter-spacing: 1px;
	text-align: center;
}

.sts-app__flow h6 {
	font-size: .875rem;
	line-height: 1.5rem;
	margin-top: .375rem;
	margin-bottom: 1.875rem;
	font-weight: 400;
	color: #587293;
	letter-spacing: 1px;
	text-align: center;
}

.sts-app__flow .form-group .form-control {
	height: 48px;
	border: 1px solid #eaeaea;
	outline: none;
	box-shadow: none;
	font-size: 13px;
	letter-spacing: 0.8px;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	background: #ffffff;
}

.sts-app__flow .form-group .form-control:focus {
	border-color: #032b5f;
}

.sts-app__flow .form-group .form-control:hover {
	border-color: #d9d9d9;
}

.sts-app__flow .form-group .form-control::-webkit-input-placeholder {
	color: rgb(196, 196, 196);
}

.sts-app__flow .form-group .form-control::-moz-placeholder {
	color: rgb(196, 196, 196);
}

.sts-app__flow .form-group .form-control:-ms-input-placeholder {
	color: rgb(196, 196, 196);
}

.sts-app__flow .form-group .form-control:-moz-placeholder {
	color: rgb(196, 196, 196);
}

.btn_log {
	border: 0 solid transparent;
	border-radius: .1875rem;
	cursor: pointer;
	display: block;
	height: 3rem;
	outline: none;
	overflow: hidden;
	padding: 0 1rem;
	position: relative;
	text-align: center;
	white-space: nowrap;
	background: #b365db;
	width: 100%;
	color: #fff;
	letter-spacing: 1px;
	font-size: 15px;
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}

.btn_log:hover {
	opacity: 0.8;
}

.cbx {
	position: relative;
	top: 1px;
	width: 17px;
	height: 17px;
	border: 1px solid #c8ccd4;
	border-radius: 3px;
	vertical-align: middle;
	transition: background 0.1s ease;
	cursor: pointer;
}

.cbx:after {
	content: '';
	position: absolute;
	top: 1px;
	left: 5px;
	width: 5px;
	height: 11px;
	opacity: 0;
	transform: rotate(45deg) scale(0);
	border-right: 2px solid #fff;
	border-bottom: 2px solid #fff;
	transition: all 0.3s ease;
	transition-delay: 0.15s;
}

.lbl {
	vertical-align: middle;
	cursor: pointer;
	margin: -5px 0 0 6px;
	color: #587293;
	letter-spacing: 1px;
	opacity: 1;
	font-size: 14px;
}

#cbx:checked~.cbx {
	border-color: transparent;
	background: #7d56d1;
	animation: jelly 0.6s ease;
}

#cbx:checked~.cbx:after {
	opacity: 1;
	transform: rotate(45deg) scale(1);
}

.cntr {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	text-align: center;
}

@-moz-keyframes jelly {
	from {
		transform: scale(1, 1);
	}
	30% {
		transform: scale(1.25, 0.75);
	}
	40% {
		transform: scale(0.75, 1.25);
	}
	50% {
		transform: scale(1.15, 0.85);
	}
	65% {
		transform: scale(0.95, 1.05);
	}
	75% {
		transform: scale(1.05, 0.95);
	}
	to {
		transform: scale(1, 1);
	}
}

@-webkit-keyframes jelly {
	from {
		transform: scale(1, 1);
	}
	30% {
		transform: scale(1.25, 0.75);
	}
	40% {
		transform: scale(0.75, 1.25);
	}
	50% {
		transform: scale(1.15, 0.85);
	}
	65% {
		transform: scale(0.95, 1.05);
	}
	75% {
		transform: scale(1.05, 0.95);
	}
	to {
		transform: scale(1, 1);
	}
}

@-o-keyframes jelly {
	from {
		transform: scale(1, 1);
	}
	30% {
		transform: scale(1.25, 0.75);
	}
	40% {
		transform: scale(0.75, 1.25);
	}
	50% {
		transform: scale(1.15, 0.85);
	}
	65% {
		transform: scale(0.95, 1.05);
	}
	75% {
		transform: scale(1.05, 0.95);
	}
	to {
		transform: scale(1, 1);
	}
}

@keyframes jelly {
	from {
		transform: scale(1, 1);
	}
	30% {
		transform: scale(1.25, 0.75);
	}
	40% {
		transform: scale(0.75, 1.25);
	}
	50% {
		transform: scale(1.15, 0.85);
	}
	65% {
		transform: scale(0.95, 1.05);
	}
	75% {
		transform: scale(1.05, 0.95);
	}
	to {
		transform: scale(1, 1);
	}
}

.hidden-xs-up {
	display: none !important;
}

.sign_in_glge {
	margin-top: 20px;
}

.sign_in_glge a {
	display: block;
	background: transparent;
	text-align: center;
	height: 48px;
	padding: 10px;
	border-radius: 3px;
	border: 1px solid #ececec;
	font-size: 13px;
	letter-spacing: 1px;
	color: #9d9d9d;
	text-transform: capitalize;
	line-height: 27px;
}

.sign_in_glge a:hover {
	background: #f2f2f2;
}

.gl_logo {
	/* background: url(../images/g_logo.svg.png) center center no-repeat; */
	display: inline-block;
	height: 1.875rem;
	width: 60px;
	vertical-align: middle;
	background-size: 100%;
	margin-left: 7px;
}

.dnt_acc {
	font-size: 13px;
	line-height: 1.5rem;
	margin-bottom: 1.875rem;
	font-weight: 400;
	color: #587293;
	text-align: center;
	margin-top: 15px;
	padding-top: 35px;
	border-top: 1px solid #eaeaea;
	letter-spacing: 1px;
}

.dnt_acc a {
	color: #a74ed5;
	font-size: 14px;
}

.frm_head {
	display: block;
	color: #717273;
	font-size: 14px;
	margin-bottom: 10px;
	letter-spacing: 1px;
}

.form-control.slct_box {
	/* background: #fff url(../images/slct_box.svg)0 0 no-repeat !important; */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-size: 14px !important;
	background-position: 97% 50% !important;
	cursor: pointer;
	outline: none !important;
	box-shadow: none !important;
}


/* Custom Radio Button Start*/

.radiotextsty {
	color: #848080;
	font-size: 13px;
	letter-spacing: 1px;
}

.customradio {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 0px;
	cursor: pointer;
	/* font-size: 18px; */
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-right: 15px;
}


/* Hide the browser's default radio button */

.customradio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}


/* Create a custom radio button */

.checkmark {
	position: absolute;
	top: 3px;
	left: 0;
	height: 18px;
	width: 18px;
	background-color: #ffffff;
	border-radius: 50%;
	border: 1px solid #BEBEBE;
}


/* On mouse-over, add a grey background color */

.customradio:hover input~.checkmark {
	background-color: transparent;
}


/* When the radio button is checked, add a blue background */

.customradio input:checked~.checkmark {
	background-color: #ffffff;
	border: 1px solid #BEBEBE;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}


/* Show the indicator (dot/circle) when checked */

.customradio input:checked~.checkmark:after {
	display: block;
}


/* Style the indicator (dot/circle) */

.customradio .checkmark:after {
	top: 2px;
	left: 2px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #b365db;
}


/* Custom Radio Button End*/

.select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #eaeaea;
	border-radius: 3px;
	height: 48px;
	padding: .375rem .75rem;
	outline: none;
	box-shadow: none;
	font-size: 13px;
	letter-spacing: 0.8px;
}

.select2-selection__rendered {
	padding: 0px !important;
	font-size: 13px;
	letter-spacing: 0.8px;
	top: 3px !important;
	position: relative;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 48px;
	width: 30px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #dfdfdf;
	height: 48px;
	border-radius: 3px;
	margin-bottom: 10px;
}

.select2-dropdown {
	border: 1px solid #eaeaea;
	background: #f9f9f9;
}

.select2-results__options li {
	font-size: 13px;
	letter-spacing: 0.8px;
	letter-spacing: 1px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #b365db;
	color: white;
}

.step_s_b,
.step_s_c,
.step_s_d,
.step_s_e {
	display: none;
}

.select2-container {
	width: 100% !important;
}

.sidebar .nav-link {
	padding: 17px 8px;
	position: relative;
	font-size: 14px;
	text-transform: capitalize;
	display: flex;
	align-items: end;
}

.sidebar .sidebar-nav {
    background: #d6ecff;
}


/* .sidebar .nav-item {
	height: 3.5rem;
} */

.navbar-brand-full {
	height: 58px !important;
}

.trm_cnd {
	font-size: 13px;
	line-height: 1.5rem;
	margin-bottom: 0;
	font-weight: 400;
	color: #587293;
	text-align: left;
	letter-spacing: 1px;
}

.trm_cnd a {
	color: #b365db;
	font-size: 14px;
}

.trm_cnd a:hover {
	text-decoration: underline !important;
}

.sidebar .nav-link:hover {
	color: #353535;
}

.sidebar .nav-link:hover .nav-icon {
	color: #353535;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.sidebar .nav-link:hover::before {
	background: #666666;
	content: "";
	position: absolute;
	left: 0;
	height: 100%;
	top: 0;
	width: 5px;
}

.sidebar-minimized .sidebar .nav-link:hover::after {
	background: #eee;
}

.sidebar .nav-dropdown.open {
	background: rgba(255, 150, 115, .1);
}

.sidebar .nav-dropdown.open .nav-link.nav-dropdown-toggle {
	background: rgba(255, 150, 115, .1);
	color: #ff5798;
	position: relative;
}

.sidebar .nav-dropdown.open .nav-link.nav-dropdown-toggle .nav-icon {
	color: #ff5798;
	background: -webkit-linear-gradient(#ff5798, #fff);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.sidebar .nav-dropdown.open .nav-link.nav-dropdown-toggle::after {
	background-image: linear-gradient(to right, #ff5798, #fe619d, #fe6ba3, #fd74a8, #fc7cad, #fc80af, #fb83b2, #fb87b4, #fb87b4, #fb87b4, #fb87b4, #fb87b4);
	content: "";
	position: absolute;
	left: 0;
	height: 100%;
	top: 0;
	width: 3px;
}

.sidebar .nav-dropdown.open .nav-link.nav-dropdown-toggle::before {
	background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nI2ZmZicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+);
}

.header-fixed .app-header {
	border: none;
	box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.17);
	    background: #002646;
}

.sidebar .nav-link.active::before {
	    background: #a75d05;
	content: "";
	position: absolute;
	left: 0;
	height: 100%;
	top: 0;
	width: 4px;
}

.sidebar .nav-link.active {
    background: #f5941f;
    color: #ffffff;
}

.sidebar .nav-link.active .nav-icon {
    color: #fcfeff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.navbar-toggler {
	outline: none !important;
}

.pace .pace-progress {
	background-image: linear-gradient(to right, #ff5798, #fe619d, #fe6ba3, #fd74a8, #fc7cad, #fc80af, #fb83b2, #fb87b4, #fb87b4, #fb87b4, #fb87b4, #fb87b4);
}

.height_top_all_page {
	height: 30px;
}


/* .table_all thead tr th {
  
  letter-spacing: 1px;
  font-weight: normal;
	font-size: 14px;
	color: #828282;
	border-bottom: 0;
	border-top: 0;
} */

.table_all thead tr th {
	font-weight: normal;
	font-size: 14px;
	/* border-bottom: 0; */
	border-top: 0;
}

.table_all {
	/* border: none !important; */
}

.card-header {
	font-size: 16px;
	color: #353535;
	background: #f7f7f7;
	border-color: #eeebeb;
	background: #eeeeee;
	padding: 5px 10px;
	text-transform: capitalize;
	font-weight: 400;
	border-radius: 0 !important;
}

.card {
	border-color: #eeebeb;
	border-radius: 3px;
}

.dataTables_length label,
.dataTables_filter label {
	font-size: 12px;
	text-transform: capitalize;
	letter-spacing: 1px;
	color: #2a295c;
}

.dataTables_length label .custom-select {
	outline: none !important;
	box-shadow: none !important;
	border-color: #e8e4e4;
}

.form-control.form-control-sm {
	outline: none !important;
	box-shadow: none !important;
}


/* .table_all tbody tr td, .table_all tbody tr td a {
	
	letter-spacing: 1px;
	font-weight: normal;
	font-size: 13px;
	color: #2a295c;
	border-bottom: 0;
	border-top: 0;
} */

.table_all tbody tr {
	background: transparent !important;
	border-radius: 0;
}

.table_all tbody tr td {
	/* border: none !important; */
	background: #fff !important;
}

.table_all tbody tr td,
.table_all tbody tr td a {
	font-weight: normal;
	font-size: 14px;
	color: #5d5c5c;
	/* border-bottom: 0; */
	/* border-top: 0; */
	border-color: #eee;
	background: #fff;
	vertical-align: middle;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}

.table_all tbody tr td a {
	background: none;
}


/* .table_all tbody tr:hover td {
	background: #ececec;
	border-color: #d7d7d7;
} */

.ac_bot .btn {
	border-radius: 50%;
	height: 28px;
	width: 28px;
	margin: 0 5px;
	border: none;
	color: #2a295c;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 6px;
	background: #f2f0f0 !important;
	box-shadow: 0 1px 6px 0 rgba(85, 85, 87, 0.39);
	font-size: 14px;
}


/* .btn.btn-light.view_dlt{
  background: #ff0c0c;
}
.btn.btn-light.view_edit {
	background: #b365db;
}
.btn.btn-light.view_bot {
	background: #2a295c;
} */

.pagination li a {
	font-size: 12px;
	letter-spacing: 1px;
	border-radius: 0 !important;
	border-color: #eeeeee !important;
}

.pagination .page-item.active .page-link {
    background: #f7941e;
	border-color: #7d56d1;
	outline: none !important;
	box-shadow: none !important;
}

.dataTables_info {
	font-size: 13px;
	letter-spacing: 1px;
	color: #2a295c;
}


/* .lft-hdr i {
	margin-right: 10px;
	height: 35px;
	width: 36px;
	background: #7d56d1;
	border-radius: 50%;
	vertical-align: middle;
	text-align: center;
	liane-height: 34px;
	color: #fff;
} */

.lft-hdr span {
    margin-right: 10px;
    height: 30px;
    width: 30px;
    background: #002646;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    line-height: 31px;
    color: #ff9900;
    box-shadow: 0 1px 6px 0 rgba(85, 85, 87, 0.39);
    display: inline-block;
}

.card_cstm {
	border: none;
	background: transparent;
	margin-bottom: 0rem;
}

.card_cstm .card-body {
	padding: 0;
	align-items: center;
}

.table_all {
	/* border: none !important; */
	border-collapse: separate !important;
	width: 100% !important;
	margin: 0 !important;
}

.btn_all_same {
	background: #f32716;
	display: block;
	color: #fff;
	font-size: 14px;
	text-align: center;
	padding: 11px 20px;
	border-radius: 3px;
	letter-spacing: 1px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 0;
}

.btn_all_same i {
	margin-right: 10px;
}

.btn_all_same:hover {
	color: #fff;
	background: #000000;
}

.all_frm_css .form-group .form-control {
	height: 48px;
	border: 1px solid #eaeaea;
	border-top-color: rgb(234, 234, 234);
	border-right-color: rgb(234, 234, 234);
	border-bottom-color: rgb(234, 234, 234);
	border-left-color: rgb(234, 234, 234);
	outline: none;
	box-shadow: none;
	font-size: 13px;
	letter-spacing: 0.8px;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	background: #ffffff;
}

.all_frm_css .form-group .form-control:focus {
	border-color: #032b5f;
}

.all_frm_css .form-group .form-control:hover {
	border-color: #d9d9d9;
}

.all_frm_css .form-group .form-control::-webkit-input-placeholder {
	color: rgb(196, 196, 196);
}

.all_frm_css .form-group .form-control::-moz-placeholder {
	color: rgb(196, 196, 196);
}

.all_frm_css .form-group .form-control:-ms-input-placeholder {
	color: rgb(196, 196, 196);
}

.all_frm_css .form-group .form-control:-moz-placeholder {
	color: rgb(196, 196, 196);
}

.add_details_map {
	height: auto;
	border: 1px solid #eaeaea;
	padding: 15px;
	margin-top: 30px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
}

.add_details_map h6 {
	/* color: #717273; */
	color: #7d56d1;
	font-size: 15px;
	margin-bottom: 10px;
	letter-spacing: 1px;
	text-transform: capitalize;
	border-bottom: 1px solid #eaeaea;
	margin: 0;
	padding: 0 0 8px 0;
	margin: 0 0 15px 0;
}

.add_details_map iframe {
	height: 260px;
	width: 100%;
}

.fileUpload {
	position: relative;
	overflow: hidden;
	margin: 0;
	width: 100%;
	text-align: left;
	height: 46px;
	border-radius: 3px;
	background: #f2f2f2 url(https://image.flaticon.com/icons/svg/130/130993.svg) 2% 50% no-repeat !important;
	color: #2a295c !important;
	border: 1px dashed #d5d5d5 !important;
	padding: 13px 13px 10px 39px;
	font-size: 13px;
	letter-spacing: 0.8px;
	background-repeat: no-repeat;
	background-size: 21px !important;
}

.fileUpload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	font-size: 20px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
	width: 100%;
	height: 46px;
}

.ui-timepicker-container {
	background: #ffff;
	border: 1px solid #e6e6e6;
	border-top: 0;
	border-radius: 0;
}

.ui-timepicker-viewport li a {
	font-size: 13px;
	margin: 7px 0;
	padding: 10px;
	background: #f4f4f4;
	border: none !important;
}

.ui-timepicker-viewport li a:hover {
	background: #e8e8e8;
}

.btn.btn_sbmt {
	width: 200px;
	height: 44px;
	background: #7d56d1;
	display: block;
	color: #fff;
	font-size: 14px;
	text-align: center;
	padding: 10px;
	border-radius: 3px;
	letter-spacing: 1px;
	float: right;
	margin-bottom: 60px;
}

.ac_bot .btn i {
	position: relative;
	left: 1px;
	top: 0px;
}

.all_tble_p {
	margin: 0;
	display: inline-block;
	word-wrap: break-word !important;
	min-width: 290px !important;
	max-width: 290px;
	width: 290px !important;
	white-space: normal;
}

table.dataTable>tbody>tr.child ul.dtr-details {
	display: block;
}

.add_task_not a {
	width: 125px;
	margin-left: 9px;
}

.add_task_not {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.btn.btn-secondary.btn_all_same {
	border: none !important;
	box-shadow: none !important;
	outline: none !important;
	width: auto;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
	color: #fff;
	background: #b365db !important;
}

.all_drp_box .dropdown-menu.show {
	min-width: 270px;
	max-width: 270px;
	width: 270px;
	left: auto !important;
	right: 0;
	transform: translate3d(2px, 40px, 0px) !important;
	-webkit-transform: translate3d(2px, 40px, 0px) !important;
	-moz-transform: translate3d(2px, 40px, 0px) !important;
	-ms-transform: translate3d(2px, 40px, 0px) !important;
	-o-transform: translate3d(2px, 40px, 0px) !important;
	-webkit-box-shadow: 0 .0625rem 1.875rem 0 #ddddddc4;
	box-shadow: 0 .0625rem 1.875rem 0 #ddddddc4;
	border-radius: 0;
	border-color: #e3e3e3ba;
}

.btn.btn-secondary.btn_all_same i {
	background: #fff;
	color: #b365db;
	width: 15px;
	height: 15px;
	font-size: 9px;
	line-height: 16px;
	border-radius: 50%;
	top: -1px;
	position: relative;
}

.btn.btn-secondary.btn_all_same::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 12px;
	vertical-align: middle;
	content: "";
	border-top: 5px solid;
	border-right: 5px solid transparent;
	border-bottom: 0;
	border-left: 5px solid transparent;
	padding-right: 0;
	margin-right: 7px;
	position: relative;
	top: 0px;
}

.all_drp_box .dropdown-menu a i {
	margin-right: 10px;
}

.all_drp_box .dropdown-menu a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 10px 20px;
	font-size: 12px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #2a295c;
	letter-spacing: 1px;
}

.all_drp_box .dropdown-menu a:hover {
	background: #f4f4f4;
}

#ad_new_task_tabs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: none;
	max-width: 760px;
	margin: 45px auto;
	width: 100%;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

#ad_new_task_tabs li.nav-item a {
	border: none;
	background: transparent;
	border-radius: 0;
	outline: none !important;
	padding: 0px;
	position: relative;
}

#ad_new_task_tabs_Content {
	margin: 0;
	border: none;
}

#ad_new_task_tabs li.nav-item a span {
	width: 45px;
	height: 45px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0 auto;
	border: 1px dashed #afadb3;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
	background-color: #fff;
}

#ad_new_task_tabs li.nav-item a p {
	margin: 11px 0 0 0;
	text-align: center;
	font-size: 13px;
	letter-spacing: 1px;
	text-transform: uppercase;
	/* color: #7d56d1; */
	color: #afadb3;
}

#ad_new_task_tabs li.nav-item a.active span {
	border-color: #7d56d1;
	/* background: #fff url(../images/tick.svg) 0 0 no-repeat; */
}

#ad_new_task_tabs li.nav-item a.active p {
	color: #7d56d1;
}

#ad_new_task_tabs li.nav-item a::after {
	content: '';
	display: block;
	width: 100%;
	height: 1px;
	position: absolute;
	border-top: 1px dashed #afadb3;
	left: 75px;
	top: 31%;
	min-width: 304px;
	right: auto;
}

#ad_new_task_tabs li.nav-item:last-child a::after {
	border: none;
}

#ad_new_task_tabs li.nav-item a.active::after {
	border-color: #7d56d1;
}

#ad_new_task_tabs_Content .tab-pane {
	padding: 0px;
}

.add_task_port_a_top {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.laft_task figure {
	width: 100px;
}

.laft_task {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 30px;
	align-items: center;
	padding: 15px;
	background: #f4f4f4;
	border-radius: 5px;
	margin-bottom: 35px;
}

.laft_task figure img {
	width: 100%;
}

.lft_add_port_a_inr {
	/* min-width: 500px;
  width: 500px; */
	min-width: 359px;
	width: 359px;
}

.lft_add_port_a_inr_b {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin-left: 35px;
	background: #f4f4f4;
	padding: 15px;
	border-radius: 5px;
	-webkit-border-radius: ;
	-moz-border-radius: ;
	-ms-border-radius: ;
	-o-border-radius: ;
}

.laft_task figcaption .rating_ul {
	padding: 0;
	margin: 0;
	list-style: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0 0 22px 0;
}

.laft_task figcaption .rating_ul li {
	font-size: 8px;
	margin-right: 3px;
	color: #e1dfdf;
}

.laft_task figcaption .rating_ul li.active {
	color: #b365db;
}

.laft_task figcaption {
	margin-left: 15px;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.laft_task figcaption h3 {
	font-size: 16px;
	letter-spacing: 1px;
}

.apnd_slct {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.employee_add {
	width: 33px;
	height: 30px;
	border-radius: 50%;
	background: #f4f4f4;
	margin-left: 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #2a295c;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.num_add_ul li img {
	width: 15px;
	margin-right: 10px;
}

.num_add_ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.num_add_ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 13px;
	margin: 8px 0;
	letter-spacing: 0.5px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.form-control.slct_box.pck_dlvry {
	background-position: 99% 50% !important;
}

.left_task_pick {
	width: 48%;
}

.pick_add_delvr_add {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.pk_addrs {
	height: 48px;
	border: 1px solid #eaeaea;
	border-top-color: rgb(234, 234, 234);
	border-right-color: rgb(234, 234, 234);
	border-bottom-color: rgb(234, 234, 234);
	border-left-color: rgb(234, 234, 234);
	border-top-color: rgb(234, 234, 234);
	border-right-color: rgb(234, 234, 234);
	border-bottom-color: rgb(234, 234, 234);
	border-left-color: rgb(234, 234, 234);
	outline: none;
	box-shadow: none;
	font-size: 13px;
	letter-spacing: 0.8px;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	/* background: #ffffff url(../images/maps-and-flags.svg)0 0 no-repeat; */
	width: 100%;
	padding: 9px 45px 9px 38px;
	border-radius: 3px;
	background-size: 21px;
	background-position: 8px 50%;
}

.pk_addrs:focus {
	border-color: #032b5f;
}

.pk_addrs:hover {
	border-color: #d9d9d9;
}

.pk_addrs::-webkit-input-placeholder {
	color: rgb(172, 172, 172);
}

.pk_addrs::-moz-placeholder {
	color: rgb(172, 172, 172);
}

.pk_addrs:-ms-input-placeholder {
	color: rgb(172, 172, 172);
}

.pk_addrs:-moz-placeholder {
	color: rgb(172, 172, 172);
}

.left_task_pick h3 {
	font-size: 18px;
	letter-spacing: 1px;
	text-align: center;
	margin-top: 25px;
	margin-bottom: 15px;
	color: #2a295c;
}

.left_task_pick div {
	position: relative;
}

.left_task_pick div .far.fa-times-circle {
	position: absolute;
	right: 11px;
	font-size: 19px;
	color: #2a295c;
	top: 13px;
	cursor: pointer;
	transform: scale(0);
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}

.btn.ad_p_addrs {
	width: 47%;
	height: 40px;
	background: #b365db;
	display: block;
	color: #fff;
	font-size: 13px;
	text-align: center;
	padding: 10px;
	border-radius: 3px;
	letter-spacing: 1px;
	margin: 20px auto 0;
	border: none !important;
	box-shadow: none !important;
}

.btn.ad_p_addrs i {
	vertical-align: middle;
	margin-left: 7px;
}

.dlvry_cmnt .pk_addrs {
	margin-top: 35px;
}

.dlvry_cmnt .pk_addrs {
	background: #fff;
	padding: 12px;
	height: auto;
}

.next_fst_tab a {
	margin-left: auto !important;
	width: 120px;
	height: 40px;
	background: #b365db !important;
	display: block;
	color: #fff !important;
	font-size: 13px;
	text-align: center;
	padding: 10px;
	border-radius: 3px;
	letter-spacing: 1px;
	border: none !important;
	box-shadow: none !important;
}

.next_fst_tab {
	margin-top: 50px;
}

.next_fst_tab a img {
	width: 17px;
	vertical-align: middle;
	margin-left: 5px;
	position: relative;
	top: -1px;
}

.rever_arrw {
	transform: rotate(180deg);
	margin: 0 9px 0 0 !important;
	top: -2px !important;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
}

.all_page_dte_picker {
	height: 40px;
	border: 1px solid #eaeaea;
	border-top-color: rgb(234, 234, 234);
	border-right-color: rgb(234, 234, 234);
	border-bottom-color: rgb(234, 234, 234);
	border-left-color: rgb(234, 234, 234);
	border-top-color: rgb(234, 234, 234);
	border-right-color: rgb(234, 234, 234);
	border-bottom-color: rgb(234, 234, 234);
	border-left-color: rgb(234, 234, 234);
	outline: none;
	box-shadow: none;
	font-size: 14px;
	letter-spacing: 0.8px;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	background: #ffffff;
	width: 100%;
	padding: 7px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
}

#ui-datepicker-div {
	background: #fff;
	/* padding: 15px 47px; */
	max-width: 325px;
	width: 100%;
	position: relative;
	/* min-height: 240px; */
	-webkit-box-shadow: 0px 7px 15px -3px rgba(219, 219, 219, 1);
	-moz-box-shadow: 0px 7px 15px -3px rgba(219, 219, 219, 1);
	box-shadow: 0px 7px 15px -3px rgba(219, 219, 219, 1);
	z-index: 999999999;
}

.ui-datepicker-calendar {
	width: 87%;
	margin: 0 auto;
}

#ui-datepicker-div .ui-datepicker-prev.ui-corner-all {
	position: absolute;
	left: 17px;
	top: 22px;
	background: #ffffff !important;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 9;
	transform: translateX(0px) translateY(-20px);
	-webkit-transform: translateX(0px) translateY(-20px);
	-moz-transform: translateX(0px) translateY(-20px);
	-ms-transform: translateX(0px) translateY(-20px);
	-o-transform: translateX(0px) translateY(-20px);
	font-size: 0px;
	border: 1px solid #ccc;
}

#ui-datepicker-div .ui-datepicker-prev.ui-corner-all span,
#ui-datepicker-div .ui-datepicker-next.ui-corner-all span {
	background: none !important;
}

#ui-datepicker-div .ui-datepicker-prev.ui-corner-all::before {
	background: url(https://image.flaticon.com/icons/svg/120/120872.svg)0 0 no-repeat;
	content: "";
	width: 20px;
	height: 20px;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	margin: auto;
	bottom: 0;
}

#ui-datepicker-div .ui-datepicker-next.ui-corner-all {
	position: absolute;
	right: 17px;
	top: 22px;
	background: #fff;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 9;
	transform: translateX(0px) translateY(-20px);
	-webkit-transform: translateX(0px) translateY(-20px);
	-moz-transform: translateX(0px) translateY(-20px);
	-ms-transform: translateX(0px) translateY(-20px);
	-o-transform: translateX(0px) translateY(-20px);
	font-size: 0px;
	border: 1px solid #ccc;
}

#ui-datepicker-div .ui-datepicker-next.ui-corner-all::before {
	background: url(https://image.flaticon.com/icons/svg/120/120873.svg)0 0 no-repeat;
	content: "";
	width: 20px;
	height: 20px;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	margin: auto;
	bottom: 0;
}

.ui-datepicker-title {
	text-align: center;
	font-size: 14px;
	text-transform: capitalize;
	margin-bottom: 20px;
	background: #f32716;
	padding: 10px 0;
	border-radius: 5px;
	letter-spacing: 1px;
	color: #fff;
	font-weight: 500;
}

.ui-datepicker-calendar thead tr th {
	text-align: center;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: normal !important;
}

.ui-datepicker-calendar tbody tr td {
	text-align: center;
	padding: 9px 4px;
	font-size: 12px;
}

.ui-state-default {
	width: 25px;
	height: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: #f4f3f3 !important;
	text-align: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
	color: #2a295c;
	margin: 0 auto;
	text-align: center !important;
	border: none !important;
}

.ui-state-default.ui-state-active {
	background: #2a295c !important;
	box-shadow: 0 1px 6px 0 rgba(85, 85, 87, 0.39);
	color: #fff !important;
	border: none !important;
}

.ui-widget.ui-widget-content {
	border: none;
}

.ui-widget-header {
	background: #f3271600 !important;
	border: none !important;
}

.ui-datepicker .ui-datepicker-header {
	position: unset !important;
}

.all_page_time {
	height: 48px;
	border: 1px solid #eaeaea;
	border-top-color: rgb(234, 234, 234);
	border-right-color: rgb(234, 234, 234);
	border-bottom-color: rgb(234, 234, 234);
	border-left-color: rgb(234, 234, 234);
	/* border-top-color: rgb(234, 234, 234); */
	/* border-right-color: rgb(234, 234, 234); */
	/* border-bottom-color: rgb(234, 234, 234); */
	/* border-left-color: rgb(234, 234, 234); */
	/* border-top-color: rgb(234, 234, 234); */
	/* border-right-color: rgb(234, 234, 234); */
	/* border-bottom-color: rgb(234, 234, 234); */
	/* border-left-color: rgb(234, 234, 234); */
	outline: none;
	box-shadow: none;
	font-size: 13px;
	letter-spacing: 0.8px;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	background: #ffffff;
	width: 100%;
	padding: 10px !important;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	min-width: 100px;
	max-width: 100px;
	margin-left: 15px;
}

.timepicker {
	background-color: #FFF;
	color: #8C8C8C;
	-webkit-box-shadow: 0px 1px 7px rgba(198, 198, 198, 0.33);
	-moz-box-shadow: 0px 1px 7px rgba(198, 198, 198, 0.33);
	box-shadow: 0px 1px 7px rgba(198, 198, 198, 0.33);
	z-index: 999999;
	/* display: block !important; */
}

.timepicker .clock {
	width: 200px;
	height: 200px;
	position: relative;
	padding: 5px;
}

.timepicker .done {
	cursor: pointer;
	text-align: center;
	text-wrap: nowrap;
	line-height: 34px;
	font-size: 14px;
	display: block;
	border-top: 1px solid #DEDEDE;
}

.timepicker .done:hover {
	background-color: #DEDEDE;
	color: #848484;
}

.timepicker .meridiem {
	position: absolute;
	bottom: 5px;
	width: 32px;
	height: 32px;
	background-color: white;
	line-height: 32px;
	font-size: 14px;
	text-align: center;
	border-radius: 50%;
	cursor: pointer;
}

.timepicker .meridiem.selected {
	background-color: #D6F0F9;
	color: #6D828C;
}

.timepicker .meridiem.am {
	left: 5px;
}

.timepicker .meridiem.pm {
	right: 5px;
}

.timepicker .bubble {
	position: absolute;
	width: 32px;
	height: 32px;
	line-height: 32px;
	font-size: 14px;
	text-align: center;
	border-radius: 50%;
	cursor: pointer;
}

.timepicker .bubble:hover {
	background-color: #ABB1B3;
	color: #2A295C;
}

.timepicker .bubble.selected {
	color: #D6F0F9;
	background-color: #2a295c;
}

.timepicker .unit {
	top: 5px;
	background-color: white;
}

.timepicker .unit.hour {
	left: 5px;
}

.timepicker .unit.minute {
	right: 5px;
}

.timepicker .face {
	width: 100%;
	height: 100%;
	background-color: white;
	border: none;
	border-radius: 50%;
	position: relative;
}

.timepicker .face:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 6px;
	height: 6px;
	margin: -3px 0 0 -3px;
	background-color: #b365db;
	border-radius: 50%;
	content: "";
	display: block;
}

.timepicker .hand {
	width: 0;
	height: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	pointer-events: none;
	background-color: #7D56D1;
}

.timepicker .hand.minute {
	margin: -30% 0 0 -1px;
	padding: 30% 1px 0;
	border-radius: 1px 1px 0 0;
}

.timepicker .hand.hour {
	margin: -23% 0 0 -2px;
	padding: 23% 2px 0;
	border-radius: 2px 2px 0 0;
}

.timepicker .hand.selected {
	background-color: #2a295c;
}

.timepicker .time {
	background-color: #F2F2F2;
}

.timepicker {
	position: absolute !important;
	left: 203px !important;
	top: 47px !important;
}

.pdf_attch {
	font-size: 22px !important;
	color: #b365db !important;
}

.ad_expen_date .form-control.slct_box {
	height: 48px;
}

.ad_expen_date {
	margin: 0px 0 0 0;
}

.ad_expen_date label {
	display: block;
	font-size: 14px;
	letter-spacing: 1px;
	color: #2a295c;
}

.dropzone {
	min-height: 268px;
	border: 1px dashed rgba(181, 181, 181, 0.3);
	padding: 20px 20px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.dropzone .dz-message {
	text-align: center;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 28% 0;
	font-size: 14px;
	letter-spacing: 1px;
	color: #2a295c;
	margin: 0px;
}

.modal-backdrop {
	background-color: #2a295c;
}

.modal-content {
	border: none;
}

#exampleModalLabel {
	font-size: 18px;
	letter-spacing: 1px;
	color: #2a295c;
}

.modal.fade.show {
	padding: 0 !important;
}

.close.close_modal {
	outline: none !important;
}

.close.close_modal span {
	font-size: 25px;
}

.add_modal_bot {
	width: 145px;
	height: 44px;
	background: #b365db;
	display: block;
	color: #fff;
	font-size: 13px;
	text-align: center;
	padding: 10px;
	border-radius: 3px;
	letter-spacing: 1px;
	border: none;
	outline: none !important;
	box-shadow: none !important;
}

.add_modal_bot:hover {
	background: #a955d5;
}

.modal-footer {
	border: none;
}

.btn.btn_all_same {
	margin-top: 40px;
	color: #fff !important;
}

.vhel_date.d-flex input:first-child {
	min-width: 60px;
	width: 60px;
	max-width: 60px;
}

.vhel_date.d-flex input:nth-child(2) {
	min-width: 60px;
	margin: 0 15px;
	width: 60px;
	max-width: 60px;
}

.vhel_date.d-flex input:last-child {
	min-width: 252px;
	width: 252px;
	max-width: 252px;
}

.ad_dors .ad_expen_date {
	width: 48%;
	max-width: 48%;
	min-width: 48%;
}

.air_check {
	margin-top: 20px;
}

.air_check label {
	margin: 0;
	font-size: 13px;
	letter-spacing: 1px;
	color: #2a295c;
}

.hubs_map iframe {
	width: 100%;
	height: 272px;
}

.vehical_dtls h5 {
	font-size: 18px;
	letter-spacing: 1px;
	color: #2a295c;
	margin-top: 12px;
}

.vehical_dtls h5 img {
	width: 40px;
	vertical-align: middle;
	margin-right: 15px;
}

.vh_dtls_p {
	display: block;
	font-size: 14px;
	letter-spacing: 1px;
	color: #2a295c;
}

.vh_dtls_p span {
	margin: 3px 0 0 0;
	display: block;
	color: #848484;
	letter-spacing: 1px;
	font-size: 13px;
}

.timepicker .unit.hour,
.timepicker .unit.minute {
	font-size: 11px;
}

.timepicker .bubble {
	position: absolute;
	width: 27px;
	height: 28px;
	line-height: 28px;
	font-size: 12px;
	text-align: center;
	border-radius: 50%;
	cursor: pointer;
	color: #2a295c;
}

.timepicker .done {
	background: #2a295c !important;
	color: #fff;
	font-size: 11px;
	letter-spacing: 1px;
}

.btn.btn_all_same.cnfrm_sch {
	width: 258px;
}

.tme_posi .timepicker {
	left: 323px !important;
	top: 89px !important;
}


/* dashboard-page-css */

.income_a.d-flex span img {
	width: 100%;
}

.income_a.d-flex .img_icon_span {
	width: 75px;
	background: #fff;
	padding: 11px;
	border-radius: 50%;
	height: 75px;
	-webkit-box-shadow: 0 1px 6px 0 rgba(104, 104, 104, 0.28);
	box-shadow: 0 1px 6px 0 rgba(104, 104, 104, 0.28);
}

.right_dash_icon_sec h2 {
	font-size: 19px;
	letter-spacing: 1px;
	color: #7d56d1;
	text-transform: capitalize;
	margin: 0;
	padding: 0px 0 10px 0;
	margin: 0 0 10px 0;
	border-bottom: 1px solid #f0f0f0;
}

.income_a {
	padding: 10px;
	background: #fff;
	border-radius: 5px;
	-webkit-box-shadow: 0 1px 6px 0 rgba(193, 193, 193, 0.28);
	box-shadow: 0 1px 6px 0 rgba(193, 193, 193, 0.28);
}

.right_dash_icon_sec,
.mnth_yr_amnt {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.mnth_yr_amnt h3 {
	margin: 0px 0 8px 0;
	font-size: 21px;
	letter-spacing: 1px;
	color: #b365db;
}

.mnth_yr_amnt p {
	margin: 0;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #9b9696;
}

.modal-content {
	border-radius: 3px;
	border: none !important;
	box-shadow: none !important;
}

.modal-title {
	font-size: 17px;
	color: #325496;
}

.sure_sec p {
	text-align: center;
	font-size: 22px;
	text-transform: capitalize;
	letter-spacing: 1px;
	color: #7d56d1;
	margin: 0 0 25px 0;
}

.sure_sec .btn {
	width: 135px;
	height: 40px;
	/* background: #b365db; */
	display: block;
	color: #fff;
	font-size: 13px;
	text-align: center;
	padding: 10px;
	border-radius: 3px;
	letter-spacing: 1px;
	margin: 0 11px;
	outline: none !important;
	box-shadow: none !important;
}

.btn.yes_p {
	background: #1e9d0c;
}

.btn.dlt_p {
	background: #e11111;
}

.close {
	opacity: 1 !important;
	width: 40px;
	height: 40px;
	padding: 0 !important;
	text-shadow: none !important;
	color: #325496;
	position: relative;
	right: 9px;
	top: 9px;
	font-size: 23px;
	outline: none !important;
}

.card.card_cstm.same_dv_table {
	-webkit-box-shadow: 0 1px 6px 0 rgba(193, 193, 193, 0.28);
	box-shadow: 0 1px 6px 0 rgba(193, 193, 193, 0.28);
	padding: 10px;
	background: #ffffff;
	margin: 20px 0 10px;
}

.sure_sec .btn i {
	font-size: 16px;
	vertical-align: middle;
	margin-right: 6px;
	position: relative;
	top: -2px;
}

.dash_lft_t .table_all thead tr th {
	font-weight: 500;
	color: #353535;
	text-align: center;
}

.table_all tbody tr td {
	background: #fff !important;
	/* border: none !important; */
	text-align: center;
	height: 100%;
}


/* dashboard-page-css */

.map {
	width: 100%;
}

.btn_red {
	background: #f32716;
	width: 100%;
	padding: 9px;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	display: block;
	font-size: 16px;
	font-weight: 500;
	border: none;
}

.btn_red:hover {
	background: #000;
}

.dash_lft_t {
	padding: 0;
	padding-top: 20px;
}

footer.app-footer a {
	color: #25aae1;
}

footer.app-footer a {
	color: #5d5c5c;
}

div#chart_div {
	box-shadow: 0 0 3px 1px #0000001c;
}

div#chart_div_bar {
	box-shadow: 0 0 3px 1px #0000001c;
}

.lft-hdr {
	display: flex;
	align-items: center;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
	background-color: #f32716;
}

table.dataTable>tbody>tr.child span.dtr-title {
	font-weight: 500;
	color: #e40000;
}

.butthead .right-hdr:not(:last-child) {
	margin-right: 10px;
}

.butthead {
	display: flex;
}

.lebel span {
	color: #000;
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 10px;
	display: block;
	border-bottom: 1px solid #e4e7ea;
}

.search_button button {
	background: #f32716;
	padding: 9px;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	border: none;
	text-transform: uppercase;
}

.search_button {
	text-align: center;
	display: flex;
	justify-content: space-between;
	flex-flow: wrap;
	margin-bottom: 23px;
}

.multiple_filter .ad_expen_date {
	margin-bottom: 10px;
}

a.custom_button {
	background: #f32716 !important;
	color: #fff !important;
	padding: 3px 6px;
	border-radius: 3px;
	font-weight: 500 !important;
	display: inline-block;
	font-size: 12px !important;
}

a.custom_button:hover {
	background: #000000 !important;
}

input.submit_button {
	background: #f32716;
	padding: 5px 16px;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	border: none;
	margin-top: 15px;
}

input.submit_button:hover {
	background: #000
}

ul.nav.navbar-nav.ml-auto.top_menu {
	margin-right: 20px;
}

ul.nav.navbar-nav.ml-auto.top_menu li:not(:last-child) a {
	margin-right: 27px;
}

li.nav-item.dropdown.show .nav-link {
    background: #f29321;
    color: #fff !important;
}

li.nav-item.dropdown.show .nav-link svg {
	color: #fff;
}

li.nav-item.dropdown .dropdown-toggle::after {
	transform: rotate(-90deg);
	transition: all 0.4s ease-in-out;
}

li.nav-item.dropdown.show .dropdown-toggle::after {
	transform: rotate(0deg);
	transition: all 0.4s ease-in-out;
}

.user_det {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 10px;
	border-bottom: 1px solid #e6e6e6;
}

.user_image img {
	height: 50px;
	width: 50px;
	border-radius: 28px;
}

.user_image_name span {
	display: flex;
	background: #e6e6e6;
	height: 50px;
	width: 50px;
	font-size: 27px;
	font-weight: 300;
	color: #7c7e7c;
	border-radius: 25px;
	justify-content: center;
	align-items: center;
	letter-spacing: -1.5px;
}

.user_welcome span {
	font-weight: 200;
	color: #6f6f6f;
	font-size: 31px;
	letter-spacing: -1.5px;
}

.user_welcome {
	margin-left: 15px;
}

.user_addimage a {
	color: #7c7e7c;
	text-transform: capitalize;
	text-decoration: underline !important;
	font-size: 12px;
	font-weight: 300;
}

.user_addimage {
	margin-left: 15px;
}

.edit_dashboard {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	height: 21px;
}

.recent_file_item {
	display: flex;
}

.recent_file_item_icon {
	font-size: 26px;
	display: flex;
	margin-right: 10px;
}

ul.recent_file_item_path {
	display: flex;
	align-items: center;
	margin-top: 4px;
}

.recent_file_item_filename p {
	font-size: 15px;
	font-weight: 400;
}

.recent_file_item_filename {
	line-height: 1;
	margin-top: 2px;
}

ul.recent_file_item_path li a {
	font-size: 12px;
	font-weight: 300;
	color: #777;
	position: relative;
	transition: all 0.3s ease-in-out;
}

ul.recent_file_item_path li a:hover {
	color: #5d5c5c;
	transition: all 0.3s ease-in-out;
}

ul.recent_file_item_path li {
	font-size: 12px;
	font-weight: 300;
	color: #777;
	position: relative;
}

ul.recent_file_item_path li:not(:last-child) {
	margin-right: 12px;
	margin-bottom: 0 !important;
}

ul.recent_file_item_path li:not(:last-child):before {
	content: '\f105';
	position: absolute;
	right: -9px;
	top: 1px;
	margin: auto !important;
	font-family: "Font Awesome 5 Free";
	font-weight: 600;
}

.recent_file_area {
	margin-top: 15px;
}

.edit_dashboard span {
	color: #7c7e7c;
	font-weight: 300;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.edit_dashboard span svg {
	margin-right: 4px;
}

.app-header .nav-item .nav-link svg {
	font-size: 16px;
	margin-right: 3px;
}

a.dropdown-item svg {
	width: 20px !important;
	margin-right: 5px;
}

.card-body.custom_card_body span {
	font-size: 12.5px;
	display: block;
	margin-top: 10px;
}

.recent_file_item_list li:not(:last-child) {
	margin-bottom: 13px;
}

.sortcut_area ul {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: wrap;
}

.sortcut_area ul li {
	width: 25%;
	margin-bottom: 17px;
}

.sortcut_area ul li:nth-last-child(-n+2) {
	margin-bottom: 0px;
}

.sortcut_area ul li a {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #000;
}

.sortcut_area ul li a span {
	border: 1px solid #ccc;
	display: flex;
	width: 70px;
	height: 70px;
	font-size: 27px;
	justify-content: center;
	align-items: center;
	border-radius: 55px;
	margin-bottom: 6px;
	    color: #f6941f;
	transition: all 0.3s ease-in-out;
}

.card-body.custom_card_body_sortcut {
	align-items: center;
	justify-content: center;
	display: flex;
}

.sortcut_area ul li a:hover span {
	    background: #002646;
	color: #fff;
	transition: all 0.3s ease-in-out;
}

.header_drop {
	width: 150px;
}

.header_drop .dropdown-menu {
	border: 1px solid #ebebeb;
}

.card-body.custom_card_body,
.card-body.custom_card_body_sortcut {
	padding: 0;
	align-items: center;
	overflow: auto;
	height: 262px;
}

.tutorials_area .nav-link {
	padding: 3px 0;
	margin-right: 10px;
}

.video_item {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.video_item_main_video {
	width: 142px;
	height: 101px;
	overflow: hidden;
	margin: auto;
}

.video_item_main_video img {
	width: 100%;
	height: 100%;
}

.tutorials_area {
	padding: 15px 0;
	height: 227px;
	overflow: auto;
}

.video_item_content span {
	font-size: 13px;
	line-height: 1.3;
	display: block;
}

.video_item_content {
	margin-top: 10px;
}

.helpful_area ul li a {
	color: #000;
	font-size: 15px;
}

.helpful_area ul {
	list-style: circle !important;
	padding-inline-start: 20px;
}

.helpful_area ul li a:hover {
	color: #5d5c5c;
}


/***.card-body.custom_card_body_sharedfolders tr th:first-child {
    padding-left: 58px;
}

.card-body.custom_card_body_sharedfolders tr th:nth-child(4) {
    width: 15%;
}

.card-body.custom_card_body_sharedfolders tr th:nth-child(3) {
    width: 10%;
}

.card-body.custom_card_body_sharedfolders tr th:nth-child(2) {
    width: 5%;
}
*****/

.card-body.custom_card_body_sharedfolders span.select {
	font-size: 15px;
	color: #ccc;
	margin-right: 8px;
	display: inline-flex;
}

.card-body.custom_card_body_sharedfolders span.foldericon {
	font-size: 21px;
	color: #f5c438;
	margin-right: 8px;
	display: inline-flex;
	align-items: flex-end;
}

.custom_card_body_sharedfolders .table_all tbody tr td:first-child {
	display: flex;
	align-items: center;
	font-weight: ;
}

.table_all tbody tr td:first-child a {
	font-weight: 400 !important;
	color: #000 !important;
}

.addbutton {
	position: relative;
	z-index: 9;
	display: flex;
	align-items: center;
}

.drop_menu {
	position: absolute;
	right: 17px;
	    background: #714400;
	width: 166px;
	color: #fff;
	text-align: center;
	bottom: -17px;
	z-index: -9;
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease-in-out;
}

.addbutton_click {
	background: #5d5c5c;
	height: 28px;
	width: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 25px;
	z-index: 9;
	transform: rotate(0deg);
	transition: all 0.3s ease-in-out;
}

.drop_menu button {
	color: #fff;
	padding: 6px;
	display: block;
	margin: auto;
	font-size: 15px;
}

.drop_menu button svg {
	margin-right: 5px;
}

.drop_menu.view_drop {
	visibility: visible;
	opacity: 1;
	transition: all 0.3s ease-in-out;
}

span.addbutton_click.cross {
	background: #000;
	transform: rotate(45deg);
	transition: all 0.3s ease-in-out;
}

.modal_button_area .submit {
background: #152751;
    color: #fff;
    padding: 10px 15px;
    border-radius: 25px;
    font-size: 13px;
    margin-right: 10px;
    width: 120px;
}

.modal_button_area .cancle {
background: #ff8710;
    color: #fff;
    padding: 13px 15px;
    border-radius: 25px;
    font-size: 14px;
    margin-right: 10px;
    width: 120px;
}

.modal_button_area {
	margin-top: 35px;
}

.serach_box {
	margin-right: 10px;
}

.sidebar .nav-item:not(:last-child) {
	border-bottom: 1px solid #eae9e9;
}

.deleteclient {
	background: #f00;
	color: #fff;
	padding: 9px 18px;
	font-size: 14px;
	border-radius: 27px;
	margin-right: 10px;
}

.deleteclient svg {
	margin-right: 5px;
	font-size: 16px;
}

.addclient {
    background: #f5931f;
    color: #fff;
    padding: 9px 18px;
    font-size: 14px;
    border-radius: 27px;
    letter-spacing: 1px;
}
.addclient:hover{
 background: #26a4da;
    
}
.addclient svg {
	margin-right: 5px;
	font-size: 16px;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
	bottom: 0.4em;
}

.serach_box_bylte {
	width: 178px;
	margin-right: 10px;
}

.serach_box_bylte .dropdown-menu.show {
	max-height: 184px !important;
}

.serach_box_bylte form {
	display: flex;
	align-items: center;
}

.serach_box_bylte label {
	width: 100%;
	margin: 0;
	font-size: 13px;
	font-weight: 400;
	color: #000;
}

.serach_box_bylte .dropdown {
	width: 60% !important;
	margin-left: 10px;
}

.addclient:hover {
	color: #fff;
}

.add_new_row button {
	background: #f5931f;
	color: #fff;
	padding: 8px 11px;
	border-radius: 31px;
}

.add_new_row button svg {
	margin-right: 5px;
}

.add_new_row {
	text-align: right;
}

.createclient_main_body {
	margin-top: 15px;
}

.createclient_main_body_item_icon {
	height: 160px;
	justify-content: center;
	align-items: center;
	display: flex;
	box-shadow: 0 0 3px 3px #0000000d;
}

.createclient_main_body_item_icon span {
display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 75px;
    background: #f1f1f1;
    color: #f5931f;
    font-size: 37px;
    border-radius: 57px;
    border: 1px solid #f5d5b0;
    margin: 0 8px;
}

.createclient_main_body_item_content {
	background: #5d5c5c;
	color: #fff;
	font-size: 16px;
	text-align: center;
	padding: 14px;
}

.createclient_main_body_item a {
	display: block;
	transition: all 0.3s ease-in-out;
	background: #fff;
}

.createclient_main_body_item a:hover {
	background: #5d5c5c69;
	display: block;
	transition: all 0.3s ease-in-out;
}

.importmodal_contentfirst {
	margin-bottom: 11px;
}

.detailcreate_area {
	background: #eeeeee;
	padding: 12px 20px;
	position: relative;
	width: 100%;
	margin-bottom: 15px;
}

.delete_add_row {
	position: absolute;
	right: 10px;
	top: 5px;
	color: #5d5c5c;
	font-size: 16px;
	cursor: pointer;
}

.createclient_main_body form {
	display: flex;
	align-items: center;
	flex-flow: wrap;
}

.createclient_main_body form .custom-control {
    width: 80%;
    margin-bottom: 15px;
}
.searchheader form {
	display: flex;
	align-items: center;
}

.searchheader {
	margin-right: 65px;
	width: 100%;
	padding-right: 25px;
}

.searchheader form button {
	background: #fff;
	height: 100%;
	display: block;
	padding: 9px;
	font-size: 17px;
}

.searchheader .form-control {
	border-radius: 0;
	border: none;
}

footer.app-footer {
	justify-content: space-between;
	flex-flow: wrap;
}

.termbox a:not(:last-child) {
	margin-right: 10px;
}


/*********login********/

section.login_inner {
	height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loginFormLeft h1 {
	text-align: center;
	font-size: 29px;
	margin-bottom: 21px;
}

.input-group-prepend {}

span.input-group-text {
	padding: 0;
	width: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
    background: #f7941e;
    color: #fff;
    border-left: 0;
    border: 1px solid#f7941e;
}

.rempass {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #777;
	margin-bottom: 20px;
}

.rempass a {
	font-size: 15px;
	color: #777;
	transition: all 500ms ease-in-out;
}

.rempass a:hover {
	color: #015fa5;
	transition: all 500ms ease-in-out;
}

.loging_buttom button {
    background: #f7941e;
    color: #fff;
    border: none;
    padding: 12px 14px;
    display: block;
    width: 60%;
    margin: auto;
    font-size: 17px;
    font-weight: 500;
    /* border-radius: 5px; */
    transition: all 500ms ease-in-out;
}

.loging_buttom button:hover {
	background: #000000;
	transition: all 500ms ease-in-out;
}

.loginFormLeft {
	padding: 60px 35px;
}

.loginFormRight {
    background: #002646;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.loginFormRight_box img {
	padding: 7px;
	margin-bottom: 10px;
}

.loginFormRight_box h2 {
	color: #fff;
	font-size: 25px;
	margin-bottom: 20px;
}

.loginFormRight_box a {
	background: #fff;
	color: #000;
	padding: 7px 20px;
	border-radius: 5px;
	transition: all 500ms ease-in-out;
}

.loginFormRight_box a:hover {
	background: #00aaf2;
	color: #fff;
	transition: all 100ms ease-in-out;
}

footer.app-footer.log_foot {
	padding: 10px;
}

.card.card_cstm.same_dv_table.cust_back_card {
	background: #dcdcdc;
}

/*********Notification********/

p.para{
    font-size: 13px;
    letter-spacing: 1px;
    margin: 15px 0;
}
.emil span {
background: #ff9900;
    width: 90px;
    height: 50px;
    padding: 6px;
    color: #fff;
    font-size: 18px;
}
.emil p{
    margin: 25px 0;
}
textarea {
  resize: none;
}
.card textarea::-webkit-input-placeholder {
color: #000 !important;
}

.card textarea:-moz-placeholder { /* Firefox 18- */
color: #000 !important; 
}

.card textarea::-moz-placeholder {  /* Firefox 19+ */
color: #000 !important;
}

.card textarea:-ms-input-placeholder {
color: #000 !important; 
}
textarea#w3review {
    width: 50%;
}
ul.bt_sec{
    padding: 50px 0;
}
ul.bt_sec li{
    display: inline-block;
    margin: 0 10px 0 0;
}
.skip {
    background: #00325d;
    color: #fff;
    padding: 9px 18px;
    font-size: 14px;
    border-radius: 27px;
    letter-spacing: 1px;
}
.skip:hover{
 background: #26a4da;
    color: #fff;
    
}



.searchinput{
    line-height: 100%;
    */: ;
    margin: 0;
    width: 87%;
    /* position: absolute; */
    text-align: center;
    top: 50%;
    /* left: 50%; */
/*   transform: translate(-50%, -50%);*/
}

.text2{
    width: 60%;
    height: 38px;
    padding: 9px;
    color: orange;
    border: 1px solid orange;
    /* box-shadow: 1px 0px 3px orange; */
    font-size: 15px;
    text-transform: capitalize;
    outline: none;
}

::-webkit-input-placeholder{
   	color: orange;
}

::-moz-input-placeholder{
	color: orange;
}

::-ms-input-placeholder{
	color: orange;
}

.text:focus{
/*	box-shadow: 1px 1px 8px orange;*/
}

.submit{
	padding: 10px;
	height: 40px;
	width: 80px;
	font-size: 13px;
	color: white;
	background: orange;
	border: none;
	margin: 10px;
	outline: none;
}

.submit:active{

}



div.dropdown {
    position: relative;
    width: 20%;
    float: left;
    margin-top: 10px;
    padding: 0;
}

div.dropdown > div.caption {
  background-color: #f2f2f2;
      padding: 13px 24px;
  border-radius: 3px;
  cursor: pointer;
}

div.dropdown > div.list {
  position: absolute;
  background-color: #f2f2f2;
  width: 100%;
  border-radius: 0 0 3px 3px;
  display: none;
        z-index: 999;
}

div.dropdown > div.list > div.item {
  padding: 12px 24px;
  cursor: pointer;
}

div.dropdown > div.list > div.item.selected {
  font-weight: bold;
}

div.dropdown > div.caption:hover,
div.dropdown > div.list > div.item:hover {
background-color: rgb(255 165 0);
    color: #fff;
}

div.dropdown.open > div.caption {
  border-radius: 3px 3px 0 0;
  border-bottom: solid 1px #999;
}

div.dropdown.open > div.list {
  display: block;
}

.opo{
    margin: 40px 0;
}
.view_drop li{
    float: left;
    font-size: 13px;
    margin-bottom: 10px;
    letter-spacing: 1px;
}


.more span .fa{
    display: inline-block;
}
.more span p{
    display: inline-block;
    margin: 0 0 0 10px;
}
.more {
    float: right;
    text-align: right;
    margin: 20px 0;
}


.title {
  font-family: inherit;
  font-size: 2rem;
  font-weight: 600;
  line-height: inherit;
  color: #252b46;
}

.paragraph {
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: inherit;
  color: #9194a1;
}

.maine .tab {
  width: 100%;
  height: auto;
}
.main .tab-menu {
  display: -webkit-box;
  display: flex;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-bottom: 1.3px solid #dbdbdb;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.maine .tab-menu-link {
  position: relative;
  overflow: hidden;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  line-height: inherit;
  cursor: pointer;
  width: calc(100% / 4);
  height: auto;
  padding: 1rem 0;
  border-bottom: 2.5px solid transparent;
  color: #9194a1;
  background: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.maine .tab-menu-link::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 2;
}
.maine .tab-menu-link.active {
  bottom: 0px;
  z-index: 0;
  overflow: hidden;
    border-bottom: 2.5px solid #ff9900;
  color: #252b46;
  background: #ffffff;
}
.maine .tab-bar {
  padding: 2.5rem 0;
  overflow: hidden;
  background: #ffffff;
}
.maine .tab-bar-content {
  display: none;
  width: 100%;
  min-height: 10rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.maine .tab-bar-content.active {
  display: block;
}
