@import url('./assets/plugins/bootstrap/css/bootstrap.min.css'); 
@import url('./assets/plugins/select_bootstrap/css/bootstrap-select.css');
@import url('./assets/plugins/datatable/css/datatables.min.css');
@import url('./assets/plugins/coreui/css/style.css');
@import url('./assets/plugins/coreui/css/buttons.css');
@import url('./assets/plugins/pace/css/pace.min.css');
@import url('./assets/font/font_awesome_5/css/all.css'); 
@import url('./assets/css/custom.css');

/* Page Loader ================================= */
.page-loader-wrapper,
.lodr-overlay {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  overflow: hidden;
  text-align: center;
}
.page-loader-wrapper p {
  font-size: 13px;
  margin-top: 10px;
  font-weight: bold;
  color: #444;
}
.page-loader-wrapper .loader,
.gif-loader {
  position: relative;
  top: calc(50% - 30px);
}

.lodr-overlay {
  background: rgba(0, 0, 0, 0.4) !important;
}
.lodr-overlay p {
  color: #fff;
}

.show-segment{
  display:block;
}

.hide-segment{
  display:none;
}

.pointer-cursor{
  cursor: pointer;
}